import styled from 'styled-components'
import { typo } from '../styles/fonts'
import colors from '../styles/colors'
import ActionPlanIcon from './taskIcons/ActionPlanIcon'
import MemoryMapIcon from './taskIcons/MemoryMapIcon'
import ManifestoIcon from './taskIcons/ManifestoIcon'
import SitSpotIcon from './taskIcons/SitSpotIcon'
import MarineCitizenshipIcon from './taskIcons/MarineCitizenshipIcon'
import MarineIdentityIcon from './taskIcons/MarineIdentityIcon'
import DeepConfidenceIcon from './taskIcons/DeepConfidenceIcon'

function BlueSpaceMapPopup( { blueSpace, mySchoolId } ) {
  const hasSignedManifesto = !!blueSpace.school.signed_manifesto
  const hasAddedMemoryMap = blueSpace.school.memory_maps.length > 0
  const hasAddedSitSpot = blueSpace.school.sit_spots.length > 0
  const hasSubmittedActionPlan = !!blueSpace.school.action_plan
  const hasSubmittedMarineCitizenship = !!blueSpace.school.marine_citizenship
  const hasSubmittedMarineIdentity = !!blueSpace.school.marine_identity
  const hasSubmittedDeepConfidence = !!blueSpace.school.deep_confidence

  return (
    <StyledBlueSpaceMarker>
      <h3>{ blueSpace.school.name }</h3>
      <StyledTasks>
        <div>
          <ManifestoIcon complete={ hasSignedManifesto } />
        </div>
        <div>
          <MemoryMapIcon complete={ hasAddedMemoryMap } />
        </div>
        <div>
          <SitSpotIcon complete={ hasAddedSitSpot } />
        </div>
        <div>
          <ActionPlanIcon complete={ hasSubmittedActionPlan } />
        </div>
        <div>
          <MarineCitizenshipIcon complete={ hasSubmittedMarineCitizenship } />
        </div>
        <div>
          <MarineIdentityIcon complete={ hasSubmittedMarineIdentity } />
        </div>
        <div>
          <DeepConfidenceIcon complete={ hasSubmittedDeepConfidence } />
        </div>
      </StyledTasks>
      <StyledButton>
        <a href={ mySchoolId === blueSpace.school.id ? '/dashboard' : `/dashboard/${ blueSpace.school.id }` }>
          View
        </a>
      </StyledButton>
    </StyledBlueSpaceMarker>
  )
}

const StyledBlueSpaceMarker = styled.div`
  padding: 0;
  margin: 0;

  h3 {
    margin: 0;
    padding: 0.5rem 1rem;
    ${ typo.diaryNotes }
    font-size: ${ typo.sizes.bumped };
    background: ${ colors.navy };
    color: ${ colors.white };
  }
`

const StyledTasks = styled.div`
  display: flex;
  margin: 0.75rem;

  > * {
    margin: 0.25rem;
    width: 120px;
  }
`

const StyledButton = styled.div`
  padding: 0 1rem 1rem;
  text-align: center;

  a {
    position: relative;
    overflow: hidden;
    display: inline-block;
    min-width: 100px;
    padding: 0.4rem 1rem;
    border: 0;
    border-radius: 5px;
    text-align: center;
    ${ typo.satoshiBold };
    font-size: ${ typo.sizes.dropped };
    text-decoration: none;
    background: ${ colors.yellow };
    color: ${ colors.navy };
    cursor: pointer;
    transition:
      background .3s ease-out,
      color .3s ease-out;

    &:hover {
      background: ${ colors.navy };
      color: ${ colors.yellow };
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${ colors.navy };
    }
  }
`

export default BlueSpaceMapPopup
