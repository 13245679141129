import { useRef, useEffect, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import styled from 'styled-components'
import mapboxgl from 'mapbox-gl'
import useLocation from '../hooks/useGeolocation'
import { useAuth } from '../lib/auth'
import Container from './layout/Container'
import BlueSpaceMapPopup from './BlueSpaceMapPopup'
import AddYourBlueSpaceButton from './AddYourBlueSpaceButton'
import 'mapbox-gl/dist/mapbox-gl.css'

function Map( { 
  blueSpaces,
  center = null,
  zoom = null,
  withButton = true,
  height = '80vh',
} ) {
  const mapNodeRef = useRef()
  const mapboxMapRef = useRef( null )
  const me = useAuth()
  const mySchoolId = me?.user?.schoolId
  const browserLocation = useLocation()

  const mapCoords = useMemo(
    () => {
      if ( center ) {
        return {
          center,
          zoom: zoom || 8,
        }
      }
      
      if ( browserLocation.done ) {
        return browserLocation.error 
          ? {
            center: [-4, 55],
            zoom: 5,
          } 
          : {
            center: [browserLocation.lng, browserLocation.lat],
            zoom: zoom || 8,
          }
      }
      
      return null
    },
    [
      browserLocation.done, 
      browserLocation.error, 
      browserLocation.lat, 
      browserLocation.lng, 
      center,
      zoom,
    ], 
  )

  const shouldShowButton = (
    withButton 
    && me?.user?.role !== 'ADMIN'
    && !me?.user?.schoolId
  )

  useEffect( () => {
    if ( 
      window === undefined 
      || !mapNodeRef.current 
      || !mapCoords
      || mapboxMapRef.current
    ) {
      return
    }
    
    mapboxMapRef.current = new mapboxgl.Map( {
      container: mapNodeRef.current,
      accessToken: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: mapCoords.center,
      zoom: mapCoords.zoom,
    } )

    if ( mapboxMapRef.current ) {
      mapboxMapRef.current.addControl( new mapboxgl.NavigationControl() )
    }
  }, [mapCoords] )

  useEffect( () => {
    if ( mapCoords && blueSpaces.length && mapboxMapRef.current ) {
      blueSpaces.forEach( blueSpace => {
        const container = document.createElement( 'div' )
        const root = createRoot( container )

        root.render( 
          <BlueSpaceMapPopup 
            blueSpace={ blueSpace } 
            mySchoolId={ mySchoolId }
          />, 
        )

        const popup = new mapboxgl.Popup( {
          anchor: 'bottom',
          closeButton: false,
        } )
          .setDOMContent( container )
          .addTo( mapboxMapRef.current )

        popup.on( 'open', () => {
          mapboxMapRef.current.flyTo( { center: [blueSpace.lng, blueSpace.lat] } )
        } )

        new mapboxgl.Marker( { color: '#0d4466' } )
          .setLngLat( [blueSpace.lng, blueSpace.lat] )
          .addTo( mapboxMapRef.current )
          .setPopup( popup )
          .togglePopup()
      } )
    }
  }, [blueSpaces, mySchoolId, mapCoords] )

  return (
    <StyledMap $height={ height }>
      <StyledMapContainer ref={ mapNodeRef } />
      <StyledCTAButton>
        <Container>
          { shouldShowButton && <AddYourBlueSpaceButton /> }
        </Container>
      </StyledCTAButton>
    </StyledMap>
  )
}

const StyledMap = styled.div<{ $height: string }>`
  position: relative;
  height: ${ p => p.$height };
  background: ${ p => p.theme.colors.yellow };
`

const StyledMapContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledCTAButton = styled.div`
  position: absolute;
  width: 100%;
  top: 4rem;
  right: 0;
  pointer-events: none;

  @media ( max-width: 600px ) {
    top: auto;
    right: auto;
    bottom: 4rem;
    left: 0;
  }
`

export default Map
