import { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useAuth } from '../lib/auth'
import PlusIcon from './icons/PlusIcon'
import Link from './Link'

function AddYourBlueSpaceButton() {
  const router = useRouter()
  const me = useAuth()
  const [isVisible, setVisible] = useState( true )
  const domRef = useRef<HTMLDivElement | null>( null )

  useEffect( () => {
    const domElement = domRef.current

    const observer = new IntersectionObserver( entries => {
      setVisible( entries[ 0 ].isIntersecting )
    }, {
      rootMargin: '-200px 0px 0px 0px',
    } )

    if ( domElement ) {
      observer.observe( domElement )
      return () => observer.unobserve( domElement )
    }
  }, [] )

  if ( me?.user?.schoolId ) {
    return null
  }

  const href = me.isAuthenticated && me.user?.role === 'SCHOOL'
    ? '/add-your-blue-space'
    : `${ router.asPath }?register`

  return (
    <StyledButton 
      ref={ domRef }
      $isVisible={ isVisible }
    >
      <Link href={ href }>
        Add your blue space to the map
        <PlusIcon />
      </Link>
    </StyledButton>
  )
}

const StyledButton = styled.div<{ $isVisible: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;

  button,
  a {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    pointer-events: auto;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 ${ p => rgba( p.theme.colors.navy, 0.5 ) };
    background: ${ p => p.theme.colors.yellow };
    color: ${ p => p.theme.colors.navy };
    ${ p => p.theme.typo.satoshiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    text-decoration: none;
    cursor: pointer;
    opacity: ${ p => p.$isVisible ? 1 : 0 };
    transition:
      background .3s ease-out,
      color .3s ease-out,
      opacity .3s ease-out;

    svg {
      margin-left: 0.5rem;
      opacity: 0.3;
      
      path {
        transition: fill .3s ease-out;
      }
    }

    &:hover {
      background: ${ p => p.theme.colors.navy };
      color: ${ p => p.theme.colors.yellow };

      svg {
        margin-left: 0.5rem;
        opacity: 1;

        path {
          fill: ${ p => p.theme.colors.yellow };
        }
      }
    }
  }
`

export default AddYourBlueSpaceButton
