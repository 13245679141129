import { useEffect, useState } from 'react'

function useGeolocation() {
  const [position, setPosition] = useState( null )
  const [done, setDone] = useState( false )
  const [error, setError] = useState( null )

  useEffect( () => {
    if ( 'geolocation' in navigator ) {
      navigator.geolocation.getCurrentPosition( 
        ( { coords } ) => {
          setPosition( { 
            lat: coords.latitude,
            lng: coords.longitude,
          } )
          setDone( true )
          setError( false )
        },
        e => {
          setPosition( null )
          setDone( true )
          setError( e.message ) 
        }, 
      )
    }
    else {
      setPosition( null )
      setDone( true )
      setError( 'Browser geolocation unsupported.' ) 
    }
  }, [] )

  return { ...position, error, done }
}

export default useGeolocation
