function PlusIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M21.1815 4.21851C19.9413 2.80713 18.3969 1.69575 16.6646 0.968203C14.9324 0.240656 13.0575 -0.0840702 11.1815 0.0185126C9.32684 0.133208 7.52415 0.677053 5.9155 1.60719C4.30684 2.53733 2.93617 3.82835 1.91151 5.37851C0.942276 6.77392 0.324737 8.38295 0.111508 10.0685C-0.108017 11.7396 -0.00275621 13.4373 0.421508 15.0685C0.685735 16.2325 1.14198 17.3444 1.77151 18.3585C2.22457 19.0324 2.80972 19.6074 3.49151 20.0485C3.5584 20.0916 3.63932 20.1074 3.71751 20.0925C3.79569 20.0776 3.86516 20.0332 3.91151 19.9685C3.93414 19.9358 3.95003 19.8989 3.95826 19.86C3.96649 19.821 3.9669 19.7808 3.95945 19.7418C3.95201 19.7027 3.93686 19.6655 3.91489 19.6323C3.89293 19.5991 3.86458 19.5706 3.83151 19.5485C3.07856 19.0402 2.46351 18.353 2.04151 17.5485C1.62563 16.7095 1.31334 15.823 1.11151 14.9085C0.750376 13.3611 0.689273 11.7589 0.931508 10.1885C1.15833 8.64559 1.75534 7.18053 2.67151 5.91851C3.63663 4.51071 4.91613 3.34682 6.40877 2.51893C7.90141 1.69104 9.56623 1.22186 11.2715 1.14851C12.9609 1.07164 14.6461 1.37392 16.2034 2.0332C17.7608 2.69248 19.1508 3.69202 20.2715 4.95851C21.5871 6.45661 22.4575 8.29278 22.7845 10.2596C23.1114 12.2264 22.8817 14.2454 22.1215 16.0885C20.7015 19.4085 17.4715 22.0185 12.6915 22.5285C11.7985 22.6302 10.8981 22.6503 10.0015 22.5885C9.12318 22.524 8.25461 22.3631 7.41151 22.1085C7.36883 22.0947 7.32385 22.0895 7.27914 22.0931C7.23444 22.0967 7.19087 22.109 7.15094 22.1295C7.0703 22.1707 7.00936 22.2423 6.98151 22.3285C6.95366 22.4147 6.96119 22.5084 7.00245 22.5891C7.04371 22.6697 7.11531 22.7307 7.20151 22.7585C8.09015 23.0504 9.00961 23.2383 9.94151 23.3185C10.8698 23.3984 11.8032 23.3984 12.7315 23.3185C17.9015 22.8485 21.4115 20.0585 22.9915 16.4985C23.8662 14.4867 24.1603 12.2703 23.8404 10.1C23.5205 7.92974 22.5995 5.89236 21.1815 4.21851Z"
        fill="#000"
      />
      <path 
        d="M9.00147 12.8284C9.71147 12.8284 10.5515 12.7584 11.4415 12.6984L11.5515 15.3784C11.5515 15.5684 11.7115 17.4484 11.7715 18.0484C11.7715 18.1184 11.7715 18.1984 11.7715 18.2684C11.7715 19.1584 11.9315 19.5884 12.1015 19.5684C12.7915 19.9784 12.4015 17.0884 12.4815 15.3784C12.4815 14.6684 12.5515 13.9584 12.5615 13.2484V12.6184C13.4715 12.5384 14.4015 12.4484 15.3015 12.3884C16.1941 12.3401 17.0888 12.3401 17.9815 12.3884C18.0261 12.3925 18.0712 12.3878 18.114 12.3744C18.1569 12.361 18.1966 12.3393 18.231 12.3105C18.2654 12.2816 18.2938 12.2463 18.3144 12.2065C18.3351 12.1667 18.3477 12.1231 18.3515 12.0784C18.357 12.0335 18.3533 11.9879 18.3404 11.9445C18.3276 11.901 18.306 11.8607 18.2769 11.826C18.2478 11.7913 18.2119 11.763 18.1714 11.7427C18.1309 11.7225 18.0867 11.7108 18.0415 11.7084C16.5159 11.5187 14.9779 11.4485 13.4415 11.4984H12.5315C12.5315 11.3784 12.5315 11.2584 12.5315 11.1284C12.4647 10.1763 12.3479 9.22828 12.1815 8.28841C12.0701 7.53367 11.8791 6.79286 11.6115 6.07841C11.4315 5.69841 11.4715 5.49841 11.2515 5.50841C11.2117 5.50702 11.1721 5.51382 11.135 5.5284C11.098 5.54299 11.0644 5.56503 11.0362 5.59317C11.0081 5.62132 10.986 5.65495 10.9715 5.69198C10.9569 5.72901 10.9501 5.76864 10.9515 5.80841C10.9515 6.25841 10.9515 5.60841 11.1615 7.06841C11.2515 7.66841 11.2715 8.28841 11.2815 8.39841L11.4015 11.5684H11.1815C10.3515 11.6284 9.58147 11.7384 8.92147 11.8084C8.49147 11.8684 8.04147 11.9084 7.59147 11.9484L5.59147 12.0584C5.54943 12.0557 5.50726 12.0613 5.4674 12.0749C5.42753 12.0886 5.39075 12.1099 5.35916 12.1378C5.32757 12.1657 5.3018 12.1995 5.28332 12.2374C5.26484 12.2752 5.25401 12.3164 5.25147 12.3584C5.24431 12.4318 5.26353 12.5053 5.30568 12.5658C5.34783 12.6263 5.41015 12.6697 5.48147 12.6884C5.61753 12.7066 5.7554 12.7066 5.89147 12.6884L7.65147 12.7884C8.09147 12.8384 8.56147 12.8484 9.00147 12.8284Z"
        fill="#000"
      />
    </svg>
  )
}

export default PlusIcon
