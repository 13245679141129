import Image from 'next/image'

function ManifestoIcon( { complete } ) {
  // const { bg, fg1, fg2 } = getIconColors( complete )
  
  return (
    <Image width={ 100 } height={ 100 } sizes="100vw" style={ { width: '100%', aspectRatio: '1' } } src={ complete ? '/images/icons/manifesto-colour.png' : '/images/icons/manifesto-grey.png' } alt="Sign the manifesto icon" />  
  )
}

export default ManifestoIcon
